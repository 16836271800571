import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridFooter, GridToolbar } from "@mui/x-data-grid";
import {
  Grid,
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  IconButton,
  Button,
  Collapse,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import axios from "axios";
import ViewCustomerInvoiceDialog from "../../components/ViewCustomerInvoiceDialog";
import moment from "moment";
import SelectWrapper from "../../components/SelectBoxDataGridCell";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DataGridExport from "../../components/DataGridExport";
import ConfirmDialog from "../../components/ConfirmDialog";
import ViewClaimDialog from "../../components/ViewClaimDialog";
// import apiUtils from "../../utils/apiUtils";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const CustomerInvoices = (props) => {
  const [pageSize, setPageSize] = React.useState(15);
  const customerId = props.customerId;
  const customerNumber = props.customerNumber;
  var updatedData;

  const [loading, setLoading] = useState(false);

  const [supplierData, setSupplierData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      axios.get("/api/v1/suppliers").then((response) => setSupplierData(response.data));
    }
    fetchData();
  }, []);

  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      axios.get("/api/v1/countries").then((response) => setCountryData(response.data));
    }
    fetchData();
  }, []);

  const [invoiceStateData, setInvoiceStateData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      axios.get("/api/v1/invoiceStates").then((response) => setInvoiceStateData(response.data));
    }
    fetchData();
  }, []);

  const [invoiceLabelData, setInvoiceLabelData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      axios.get("/api/v1/invoiceLabels").then((response) => setInvoiceLabelData(response.data));
    }
    fetchData();
  }, []);

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      axios.get("/api/v1/productTypes").then((response) => setProductData(response.data));
    }
    fetchData();
  }, []);

  const [currencyData, setCurrencyData] = useState([]);

  useEffect(() => {
    axios.get("/api/v1/currencies").then((response) => setCurrencyData(response.data));
  }, []);

  const [customerPreFinanceData, setCustomerPreFinanceData] = useState([]);

  useEffect(() => {
    axios.get("/api/v1/preFinanceByEntityId/" + customerId).then((response) => setCustomerPreFinanceData(response.data));
  }, [customerId]);

  const [customerClaimsData, setCustomerClaimsData] = useState([]);

  useEffect(() => {
    axios.get("/api/v1/claimsByEntityId/" + customerId).then((response) => setCustomerClaimsData(response.data));
  }, [customerId]);

  // const [supplierData, setSupplierData] = useState([]);
  // const [currencyData, setCurrencyData] = useState([]);
  // const [productData, setProductData] = useState([]);
  // const [countryData, setCountryData] = useState([]);
  // const [customerPreFinanceData, setCustomerPreFinanceData] = useState([]);

  // useEffect(() => {
  //   // Fetch and set supplier data
  //   const fetchedSupplierData = apiUtils.useApiData("/api/v1/suppliers");
  //   setSupplierData(fetchedSupplierData);

  //   // Fetch and set currency data
  //   const fetchedCurrencyData = apiUtils.useApiData("/api/v1/currencies");
  //   setCurrencyData(fetchedCurrencyData);

  //   // Fetch and set product data
  //   const fetchedProductData = apiUtils.useApiData("/api/v1/productTypes");
  //   setProductData(fetchedProductData);

  //   // Fetch and set country data
  //   const fetchedCountryData = apiUtils.useApiData("/api/v1/countries");
  //   setCountryData(fetchedCountryData);

  //   // Fetch and set customer pre-finance data
  //   const fetchedCustomerPreFinanceData = apiUtils.useApiData(
  //     "/api/v1/preFinanceByEntityId/" + customerId
  //   );
  //   setCustomerPreFinanceData(fetchedCustomerPreFinanceData);
  // }, [customerId]);

  // console.table(customerPreFinanceData);

  const [viewClaimDialog, setViewClaimDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
  });

  const columns = [
    {
      field: "customerInvoiceId",
      headerName: "id",
      flex: 0.5,
      hide: true,
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      flex: 0.6,
      hide: false,
    },
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      flex: 0.35,
      valueFormatter: (params) => moment(params?.value).format("DD-MM-YYYY"),
    },
    {
      field: "supplierId",
      headerName: "Supplier",
      flex: 0.9,
      valueGetter: (params) =>
        supplierData.length > 0 ? supplierData.find((supplier) => supplier.supplierId === params.row.supplierId).supplierName : "",
    },
    {
      field: "countryCode",
      headerName: "Country",
      flex: 0.25,

      valueGetter: (params) =>
        countryData.length > 0 ? countryData.find((country) => country.countryId === params.row.countryCode).countryCode : "",
    },
    {
      field: "currencyId",
      headerName: "Currency",
      flex: 0.25,
      valueGetter: (params) =>
        currencyData.length > 0 ? currencyData.find((cur) => cur.currencyId === params.row.currencyId).isoCode : "",
    },
    {
      field: "invoiceVATAmountLocalCurrency",
      headerName: "Loc. VAT Amount",
      flex: 0.4,
      align: "right",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "15px",
          }}
        >
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "invoiceStateId",
      headerName: "Invoice State",
      flex: 0.33,
      valueGetter: (params) =>
        invoiceStateData.length > 0
          ? invoiceStateData.find((invoiceState) => invoiceState.invoiceStateId === params.row.invoiceStateId).invoiceStateShortCode
          : "",
    },
    {
      field: "claimState",
      headerName: "Claim State",
      flex: 0.33,
      renderCell: (params) => {
        // Ensure claimId is treated as a string and checked if it's not empty
        const claimId = String(params.row.claimId).trim();

        if (claimId) {
          // console.table(customerClaimsData);
          // console.log(claimId);
          const claim = customerClaimsData.find((c) => String(c.claimId) === claimId);
          // console.log(claim);

          if (claim) {
            let claimStateLabel = "";
            switch (claim.claimStateId) {
              case 4:
                claimStateLabel = "Pending";
                break;
              case 16:
                claimStateLabel = "Finished";
                break;
              default:
                claimStateLabel = ""; // Default case if no relevant stateId matches
            }

            if (claimStateLabel) {
              return (
                <Button
                  variant="text"
                  onClick={() => {
                    setViewClaimDialog({
                      isOpen: true,
                      title: "View Claim",
                      subTitle: "Details for Claim " + claimId,
                      params: params.row, // Pass the row data to the dialog
                      custInfo: [{ customerId: params.row.customerId, customerNumber: params.row.customerNumber }],
                    });
                  }}
                >
                  {claimStateLabel}
                </Button>
              );
            }
          }
        }
        return ""; // Return empty string if no claimId or claim not found
      },
    },

    {
      field: "preFinanceId",
      headerName: "Pre-Financed",
      flex: 0.33,
      renderCell: (params) => (params.value > 0 ? <CheckCircleOutlineIcon style={{ color: "green", margin: "0 auto" }} /> : ""),
    },
    {
      headerName: "Pre-Financed Date",
      flex: 0.4,
      valueGetter: (params) => {
        const found = customerPreFinanceData.find((item) => item.preFinanceId === params.row.preFinanceId);
        return found ? moment(found.payoutDate).format("DD-MM-YYYY") : "";
      },
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "15px",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "fileId",
      headerName: "PDF Available",
      flex: 0.35,
      renderCell: (params) =>
        params.value > 0 ? (
          <CheckCircleOutlineIcon style={{ color: "green", margin: "0 auto" }} />
        ) : (
          <CheckCircleOutlineIcon style={{ color: "red", margin: "0 auto" }} />
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.35,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Invoice",
                  subTitle: "Are you sure you want to delete " + params.row.shortCode + "?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewCustomerInvoiceDialog({
                  isOpen: true,
                  downloadFile: true,
                  title: "Manage Invoice",
                  params: params.row,
                  custInfo: [{ customerId: customerId, customerNumber: customerNumber }],
                  productInfo: [
                    {
                      productCode: params.row.claimProductCode,
                      productSubCode: params.row.claimProductSubCode,
                    },
                  ],
                  subTitle: "Are you sure you want to manage " + params.row.contactName + "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                  handleFile: () => {
                    handleFile(updatedData);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleFile = (updatedData) => {};

  const [tableData, setTableData] = useState([]);

  // console.table(tableData);

  const fetchCustomerInvoices = useCallback(async () => {
    try {
      const response = await axios.get("/api/v1/customerInvoicesByEntityIdWithProductCodes/" + customerId);
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching customer invoices by entity ID:", error);
    }
  }, [customerId]);

  useEffect(() => {
    let isCancelled = false;

    const fetchAsync = async () => {
      try {
        const response = await axios.get("/api/v1/customerInvoicesByEntityIdWithProductCodes/" + customerId);
        if (!isCancelled) {
          setTableData(response.data);
        }
      } catch (error) {
        console.error("Error fetching customer invoices by entity ID:", error);
      }
    };

    fetchAsync();

    return () => {
      isCancelled = true;
    };
  }, [fetchCustomerInvoices, customerId]);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {}, // Empty function for now, will be updated when needed
  });

  const [viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
    productInfo: [],
  });

  function onEditConfirmed(e, params) {
    window.location.reload(false);
    setViewCustomerInvoiceDialog({
      ...viewCustomerInvoiceDialog,
      isOpen: false,
    });
  }

  const handleEditCellChange = (params) => {
    const updatedRows = tableData.map((row) => (row.id === params.id ? { ...row, [params.field]: params.props.value } : row));
    setTableData(updatedRows);
  };

  function onDeleteConfirmed(e, params) {
    var submitInError = false;
    axios
      .delete("/api/v1/contacts/" + params.contactId)
      .then((response) => {
        submitInError = false;
      })
      .catch(function (error) {
        if (error.response) {
          submitInError = true;
          if (error.response.data.includes("Cannot insert duplicate key row in object")) {
          }
        } else if (error.request) {
          submitInError = true;
        } else {
          submitInError = true;
        }
      });
    if (!submitInError) {
      var tableData2 = tableData.filter((currency) => currency.currencyId !== params.currencyId);
      setTableData(tableData2);
    }

    setConfirmDialog({ ...confirmDialog, isOpen: false });
  }
  const [selectedCountry, setSelectedCountry] = useState("any");
  const [selectedYear, setSelectedYear] = useState("any");
  const [selectedStartMonth, setSelectedStartMonth] = useState("any");
  const [selectedEndMonth, setSelectedEndMonth] = useState("any");
  const [selectedState, setSelectedState] = useState("any");

  const getPreviewData = useCallback(async () => {
    setLoading(true);
    await axios.get("/api/v1/customerInvoicesByEntityIdWithProductCodes/" + customerId).then((response) => {
      const invoicesReturn = response.data;
      let invoicesFiltered = invoicesReturn;
      if (selectedCountry !== "any") {
        invoicesFiltered = invoicesFiltered.filter((inv) => inv.countryCode === selectedCountry);
      }
      if (selectedYear !== "any") {
        invoicesFiltered = invoicesFiltered.filter((inv) => {
          const invYear = new Date(inv.invoiceDate).getFullYear();
          return invYear === selectedYear;
        });
      }
      if (selectedYear !== "any" && selectedStartMonth !== "any" && selectedEndMonth !== "any") {
        invoicesFiltered = invoicesFiltered.filter((inv) => {
          const invYear = new Date(inv.invoiceDate).getFullYear();
          const invMonth = new Date(inv.invoiceDate).getMonth();
          const filterStartDate = new Date(selectedYear, selectedStartMonth - 1, 1);
          const filterEndDate = new Date(selectedYear, selectedEndMonth, 0);
          const invoiceDate = new Date(invYear, invMonth, 1);
          return invoiceDate >= filterStartDate && invoiceDate <= filterEndDate;
        });
      }
      if (selectedState !== "any") {
        invoicesFiltered = invoicesFiltered.filter((inv) => inv.invoiceStateId === selectedState);
      }
      console.log("invoicesFiltered", invoicesFiltered);
      setTableData(invoicesFiltered);
      setLoading(false);
    }, 2000);
  }, [customerId, selectedCountry, selectedYear, selectedStartMonth, selectedEndMonth, selectedState]);

  function generateArrayOfYears() {
    var max = new Date().getFullYear() + 1;
    var min = max - 5;
    var years = [];
    for (var i = max; i >= min; i--) {
      years.push({ yearId: i, yearText: i });
    }

    return years;
  }

  let yearArr = generateArrayOfYears().reverse();

  const monthArr = [
    { monthId: 1, monthText: "Jan" },
    { monthId: 2, monthText: "Feb" },
    { monthId: 3, monthText: "Mar" },
    { monthId: 4, monthText: "Apr" },
    { monthId: 5, monthText: "May" },
    { monthId: 6, monthText: "Jun" },
    { monthId: 7, monthText: "Jul" },
    { monthId: 8, monthText: "Aug" },
    { monthId: 9, monthText: "Sep" },
    { monthId: 10, monthText: "Oct" },
    { monthId: 11, monthText: "Nov" },
    { monthId: 12, monthText: "Dec" },
  ];

  const handleCountryValue = (value, name) => {
    setSelectedCountry(value);
  };
  const handleYearValue = (value, name) => {
    setSelectedYear(value);
  };
  const handleStartMonthValue = (value, name) => {
    setSelectedStartMonth(value);
  };
  const handleEndMonthValue = (value, name) => {
    setSelectedEndMonth(value);
  };
  const handleStateValue = (value) => {
    setSelectedState(value);
  };

  const handleFilter = async () => {
    getPreviewData();
  };

  useEffect(() => {
    getPreviewData();
  }, [selectedCountry, selectedYear, getPreviewData]);

  const handleResetFilter = async () => {
    try {
      const response = await axios.get("/api/v1/customerInvoicesByEntityIdWithProductCodes/" + customerId);
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching customer invoices by entity ID:", error);
    }
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedInvoiceCount, setSelectedInvoiceCount] = useState(0);
  const [selectedInvoiceTotalAmount, setSelectedInvoiceTotalAmount] = useState(0);
  const [selectedInvoiceTotalVATAmount, setSelectedInvoiceTotalVATAmount] = useState(0);
  const [isPreFinancedDisabled, setIsPreFinancedDisabled] = useState(false);

  // const [
  //   selectedInvoiceTotalVATAmountEUR,
  //   setSelectedInvoiceTotalVATAmountEUR,
  // ] = useState(0);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
    let selectedRowsAmount = 0;
    let selectedRowsVATAmount = 0;
    let disablePreFinanced = false;

    newSelection.forEach((rowId) => {
      const selectedRow = tableData.find((row) => row.customerInvoiceId === rowId);
      if (selectedRow) {
        selectedRowsAmount += selectedRow.invoiceGrossAmount;
        selectedRowsVATAmount += selectedRow.invoiceVATAmountLocalCurrency;

        // Check if preFinancedId is greater than 0
        if (selectedRow.preFinanceId > 0) {
          disablePreFinanced = true;
        }
      }
    });

    setSelectedInvoiceCount(newSelection.length);
    setSelectedInvoiceTotalAmount(selectedRowsAmount);
    setSelectedInvoiceTotalVATAmount(selectedRowsVATAmount);

    // Update the button's disabled state
    setIsPreFinancedDisabled(disablePreFinanced);
  };

  const handleSetState = () => {
    if (selectedRows.length <= 0) {
      setOpenAlert(true);
      setAlertSeverity("error");
      setAlertMessage("No invoices selected");
      setTimeout(() => {
        setOpenAlert(false);
      }, 5000);
      return;
    }

    // Show the custom ConfirmDialog
    setConfirmDialog({
      isOpen: true,
      title: "Confirm Set State",
      subTitle: "Are you sure you want to set selected invoices to state 'RFC'?",
      onConfirm: () => {
        // Set state Id to 3 (RFC)
        const invoiceStateId = 3;

        let rowsToBeUpdated = [];
        selectedRows.forEach((rowId) => {
          const selectedRow = {};

          selectedRow.customerInvoiceId = rowId;
          selectedRow.invoiceStateId = invoiceStateId;

          if (selectedRow) {
            rowsToBeUpdated.push(selectedRow);
          }
        });
        const jsonRowsToBeUpdated = JSON.stringify(rowsToBeUpdated);
        axios
          .put("/api/v1/customerInvoices/updateInvoiceStateId", jsonRowsToBeUpdated, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status !== 204) {
              // Replace 200 with the actual status code your API returns upon successful update
              throw new Error("Failed to update invoice states");
            }
            // console.table(response.data);
            getPreviewData();
            setSelectedRows(0);
            setOpenAlert(true);
            setAlertSeverity("success");
            setAlertMessage("Invoice states are updated!");
            setTimeout(() => {
              setOpenAlert(false);
            }, 5000);
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          })
          .catch((error) => {
            setOpenAlert(true);
            setAlertSeverity("error");
            setAlertMessage(error.message);
            setTimeout(() => {
              setOpenAlert(false);
            }, 5000);
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          });
      },
      onCancel: () => {
        setConfirmDialog({ ...confirmDialog, isOpen: false });
      },
    });
  };

  const handlePreFinanced = () => {
    if (selectedRows.length <= 0) {
      setOpenAlert(true);
      setAlertSeverity("error");
      setAlertMessage("No invoices selected");
      setTimeout(() => {
        setOpenAlert(false);
      }, 5000);
      return;
    }

    // Show the custom ConfirmDialog
    setConfirmDialog({
      isOpen: true,
      title: "Confirm Pre-Finance",
      subTitle: "Are you sure you want to set selected invoices to pre-financed?",
      onConfirm: () => {
        // Pre-finance action
        let newPreFinance = {
          entityId: customerId,
          creationDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
          payoutAmount: selectedInvoiceTotalVATAmount,
          payedOut: 0,
        };
        axios
          .post("/api/v1/initialCreatePreFinance", newPreFinance)
          .then((response) => {
            if (response.status !== 201) {
              // Replace 201 with the actual status code your API returns upon successful creation
              throw new Error("Failed to create preFinance");
            }
            // console.log(response);
            // Extract ID from the response
            const returnedId = response.data.preFinanceId;

            let rowsToBePreFinanced = [];
            selectedRows.forEach((rowId) => {
              const selectedRow = {};

              selectedRow.customerInvoiceId = rowId;
              selectedRow.preFinanceId = returnedId;

              if (selectedRow) {
                rowsToBePreFinanced.push(selectedRow);
              }
            });
            const jsonRowsToBePreFinanced = JSON.stringify(rowsToBePreFinanced);

            // Now use this ID in your PUT request
            return axios.put("/api/v1/customerInvoices/updateInvoicePreFinance", jsonRowsToBePreFinanced, {
              headers: {
                "Content-Type": "application/json",
              },
            });
          })
          .then((response) => {
            if (response.status !== 204) {
              // Replace 200 with the actual status code your API returns upon successful update
              throw new Error("Failed to update invoicePreFinance");
            }
            // console.table(response.data);
            getPreviewData();
            setSelectedRows(0);
            setOpenAlert(true);
            setAlertSeverity("success");
            setAlertMessage("Pre-finance settings are saved!");
            setTimeout(() => {
              setOpenAlert(false);
            }, 5000);
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          })
          .catch((error) => {
            setOpenAlert(true);
            setAlertSeverity("error");
            setAlertMessage(error.message);
            setTimeout(() => {
              setOpenAlert(false);
            }, 5000);
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          });
      },
    });
  };

  const BulkEditFooter = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: "1px solid #ccc",
          paddingTop: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Selected Invoices:</div>
            <div style={{ marginBottom: 5 }}>{selectedInvoiceCount}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Net Amount:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedInvoiceTotalAmount.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total VAT Amount:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedInvoiceTotalVATAmount.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          {/* <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}
          >
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>
              Total VAT Amount EUR:
            </div>
            <div style={{ marginBottom: 5 }}>
              {selectedInvoiceTotalVATAmountEUR.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div> */}
        </div>
        <div>
          <div>
            <GridFooter
              style={{ borderTop: "none" }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[15, 50, 100]}
            />
          </div>
        </div>
      </div>
    );
  };

  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  // const showConfirmationDialog = () => {
  //   return window.confirm(
  //     "Are you sure you want to set selected invoices to pre-financed?"
  //   );
  // };

  return (
    <React.Fragment>
      <ViewClaimDialog
        viewClaimDialog={viewClaimDialog}
        setViewClaimDialog={setViewClaimDialog}
        // refreshTable={fetchClaimsByEntityId}
      />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />

      <Box sx={{ width: "100%" }}>
        <Collapse in={openAlert}>
          <Alert
            severity={alertSeverity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alertMessage}
          </Alert>
        </Collapse>
      </Box>

      <Helmet title="Customer Invoices" />

      <Divider my={6} />
      <Grid item xs={12} md={6}></Grid>
      <Card mb={0}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 0,
          }}
        ></CardContent>
        <Grid container spacing={0} mt={0}>
          {/* <Grid container xs={12} sx={{ mb: 5 }}> */}
          <Grid container xs={12} spacing={5} sx={{ pr: 2 }}>
            <Grid item xs={2}>
              <SelectWrapper
                name="countryId"
                labelColor="#f64435"
                label="Country"
                options={[
                  { key: "any", value: "Any" },
                  ...JSON.parse(
                    JSON.stringify(JSON.parse(JSON.stringify(countryData).split('"countryId":').join('"key":')))
                      .split('"countryCode":')
                      .join('"value":')
                  ),
                ]}
                value={selectedCountry}
                handleChange={handleCountryValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="year"
                label="Year"
                // value={year}
                options={[
                  { key: "any", value: "Any" },
                  ...JSON.parse(
                    JSON.stringify(JSON.parse(JSON.stringify(yearArr).split('"yearId":').join('"key":')))
                      .split('"yearText":')
                      .join('"value":')
                  ),
                ]}
                value={selectedYear}
                handleChange={handleYearValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="startMonth"
                label="Start month"
                options={[
                  { key: "any", value: "Any" },
                  ...JSON.parse(
                    JSON.stringify(JSON.parse(JSON.stringify(monthArr).split('"monthId":').join('"key":')))
                      .split('"monthText":')
                      .join('"value":')
                  ),
                ]}
                value={selectedStartMonth}
                handleChange={handleStartMonthValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="endMonth"
                label="End month"
                options={[
                  { key: "any", value: "Any" },
                  ...JSON.parse(
                    JSON.stringify(JSON.parse(JSON.stringify(monthArr).split('"monthId":').join('"key":')))
                      .split('"monthText":')
                      .join('"value":')
                  ),
                ]}
                value={selectedEndMonth}
                handleChange={handleEndMonthValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="invoiceStateId"
                label="Invoice State"
                options={[
                  { key: "any", value: "Any" },
                  ...invoiceStateData.map((state) => ({
                    key: state.invoiceStateId,
                    value: state.invoiceStateShortCode,
                  })),
                ]}
                value={selectedState}
                handleChange={handleStateValue}
              />
            </Grid>
            <Grid item xs={2}>
              <Button onClick={handleFilter} sx={{ mt: 4, pl: 6, pr: 6 }} variant="contained">
                Filter
              </Button>
              <Button onClick={handleResetFilter} sx={{ mt: 4, ml: 4, pl: 6, pr: 6 }} variant="outlined">
                Reset
              </Button>
            </Grid>
          </Grid>
          {viewCustomerInvoiceDialog.isOpen && (
            <ViewCustomerInvoiceDialog
              key={viewCustomerInvoiceDialog.params?.customerInvoiceId || Date.now()}
              viewCustomerInvoiceDialog={viewCustomerInvoiceDialog}
              setViewCustomerInvoiceDialog={setViewCustomerInvoiceDialog}
              countryArr={countryData}
              supplierArr={supplierData}
              currencyArr={currencyData}
              productTypeArr={productData}
              invoiceStateArr={invoiceStateData}
              invoiceLabelArr={invoiceLabelData}
              refreshGrid={getPreviewData}
            />
          )}
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "600px",
                }}
              >
                <Typography sx={{ mr: 6, fontSize: 20 }}>Loading</Typography>
                <CircularProgress />
              </div>
            ) : (
              <DataGrid
                // isRowSelectable={(params) =>
                //   params.row.preFinanceId <= 0 ||
                //   params.row.preFinanceId == null
                // }
                checkboxSelection
                // disableSelectionOnClick={true}
                idProperty="id"
                editMode="cell"
                getRowId={(row) => row.customerInvoiceId}
                onEditCellChange={handleEditCellChange}
                selectionModel={selectedRows}
                onSelectionModelChange={handleSelectionChange}
                components={{ Toolbar: GridToolbar, Footer: BulkEditFooter }}
                density="compact"
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: false },
                    printOptions: { disableToolbarButton: false },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                rowIndexColumn
                experimentalFeatures={{ newEditingApi: true }}
                rows={tableData}
                columns={columns}
                pagination
                paginationMode="client"
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[15, 50, 100]}
                sx={{
                  height: 677,
                  width: "100%",
                  borderRadius: 1,
                  backgroundColor: "background.paper",
                  boxShadow: 2,
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                  marginTop: 0,
                }}
              />
            )}
            <div style={{ alignSelf: "flex-end", marginBottom: 5, marginTop: 10 }}>
              <Button variant="contained" color="primary" onClick={handlePreFinanced} disabled={isPreFinancedDisabled} sx={{ mr: 3 }}>
                Set selected to pre-financed
              </Button>

              <Button
                variant="contained"
                color="success"
                onClick={handleSetState}
                // disabled={isPreFinancedDisabled}
              >
                Set Invoice State to RFC
              </Button>
            </div>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CustomerInvoices;
